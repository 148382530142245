import cloneDeep from 'lodash.clonedeep';

const apiPaths = {
  people: '/users/:userId/people',//post
  peopleQuery: '/users/:userId/people/query',//post
  person: '/users/:userId/people/:id', //get, put, delete
  mergePeople: '/users/:userId/people/:sourceId/merge/:destinationId',
  updateMultiple: '/users/:userId/people/update_multiple',
  faceRecognitionStatus: '/users/:userId/facial_recognition_status'
};

const morphUpdatePerson = person => {
  const morphedPerson = cloneDeep(person);

  if (morphedPerson.person_alternate_names) {
    morphedPerson.person_alternate_names_attributes = morphedPerson.person_alternate_names;
    delete morphedPerson.person_alternate_names;
  }

  return morphedPerson;
}

export function usePeopleApi() {
  return {

    async getPeople({cursor, search, namedStatus, displayStatus = [PEOPLE_DISPLAY_STATUS.visible], order, userId = useSessionStore().currentUserId}) {
      const data = await useMarsApiV3().post(apiPaths.peopleQuery, {
        params: {
          route: {userId}
        },
        body: {
          search: {
            q: search
          },
          filter: {
            named_status: namedStatus,
            display_status: displayStatus,
            order,
          },
          cursor
        }
      });

      data.people = data.people.map(personMapper);
      return data;
    },

    async getPerson({id, userId = useSessionStore().currentUserId, shareToken}) {
      const data = await useMarsApiV3().fetch(apiPaths.person, {
        params: {
          route: {userId, id},
          query: {
            share_token: shareToken
          }
        }
      });

      return personMapper(data.person);
    },

    async createPerson({person}) {
      const data = await useMarsApiV3().post(
        apiPaths.people,
        {
          params: {
            route: {userId: useSessionStore().currentUserId}
          },
          body: {...morphUpdatePerson(person)}
        }
      );

      return personMapper(data.person);
    },

    async updatePerson({id, updates}) {
      const data = await useMarsApiV3().put(
        apiPaths.person,
        {
          params: {
            route: {
              id,
              userId: useSessionStore().currentUserId
            }
          },
          body: {person: morphUpdatePerson(updates)}
        });


      return personMapper(data.person);
    },

    mergePeople({source, destination, userId = useSessionStore().currentUserId}) {
      return useMarsApiV3().post(
        apiPaths.mergePeople,
        {
          params: {
            route: {
              userId,
              sourceId: source.id,
              destinationId: destination.id
            }
          }
        }
      );
    },

    async getFaceRecognitionStatus() {
      return await useMarsApiV3().fetch(apiPaths.faceRecognitionStatus, {
        params: {route: {userId: useSessionStore().currentUserId}}
      });
    }

    //if we do sharing of people we can add it here
    /*destroyTags({ids}) {
      return useMarsApiV3().post(
        apiPaths.destroyTags,
        {
          params: {route: {userId: useSessionStore().currentUserId}},
          body: {ids}
        }
      );
    },

    disableShareToken({id}) {
      const route = {
        id,
        userId: useSessionStore().currentUserId
      };

      return useMarsApiV3().post(
        apiPaths.disableShareToken,
        {
          params: {route}
        }
      );
    },

    resetShareToken({id}) {
      const route = {
        id,
        userId: useSessionStore().currentUserId
      };

      return useMarsApiV3().post(
        apiPaths.resetShareToken,
        {
          params: {route}
        }
      );

      return data.share_token;
    }


    */
  };
}
